@import '@core/styles/vars';

.PageNavCategory {
  $block: &;

  color: var(--color-text-muted);
  list-style: none;
  margin-top: var(--PageNav-gap);

  &-content {
    border-radius: var(--border-radius);
    cursor: pointer;
    height: 26px;
    margin: 0;
    padding: 0 var(--PageNav-padding);
    position: relative;
    transition: background 60ms linear;

    &-chevron {
      margin: 2px;
      transition: transform var(--transition-fast) linear;
    }

    &_nohover #{$block}-content-chevron {
      opacity: 0;
    }

    &_show-controls,
    &:hover:not(#{&}_nohover) {
      background: var(--color-bg-hover);

      #{$block} {
        &-content-chevron {
          opacity: 1;
        }

        &-badge {
          visibility: hidden;
        }

        &-meta-controls {
          opacity: 1;
          position: unset;
        }
      }
    }

    &_opened {
      #{$block}-content-chevron {
        opacity: 0;
        transform: rotate(90deg);
      }

      #{$block}-count-label {
        display: none;
      }

      &:hover,
      &:active,
      &:focus {
        #{$block}-count-label {
          display: inline-block;
        }
      }
    }

    &_disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &_disabled-collapse {
      pointer-events: none;
    }
  }

  &-toggle {
    background: none;
    border: 0;
    color: currentcolor;
    cursor: pointer;
    flex: 1 1 auto;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    line-height: calc(4 / 3);
    margin: 0;
    min-width: 0;
    padding: 0;
    text-align: left;
    text-transform: uppercase;
  }

  &-input {
    font-size: 12px;
    margin-left: calc(var(--xs) * -1);
    margin-right: calc(var(--xs) * -1);

    &-container {
      text-transform: none;
      width: 100%;
    }
  }

  &-label {
    flex: 1 1 auto;
    min-width: inherit;
  }

  &-truncated-label {
    overflow: hidden;
    padding: var(--xs) 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-count-label {
    color: var(--color-text-minimum);
    font-size: 10px;
    font-weight: var(--font-weight-normal);
  }

  &-badge {
    visibility: visible;
  }

  &-meta-controls {
    opacity: 0;
    position: absolute;
  }

  &-children {
    margin: 0;
    padding: var(--PageNav-gap) 0;
  }

  &_dragging {
    background: rgba(var(--blue-rgb), 0.1);
    color: var(--blue);
    pointer-events: none;
  }
}
