@import '@core/styles/vars';

.PageNavItem {
  $block: &;

  color: var(--color-text-muted);
  list-style: none;
  position: relative;

  + .PageNavItem {
    margin-top: var(--PageNav-gap);
  }

  &-content {
    border-radius: var(--border-radius);
    padding: 0 var(--PageNav-padding);
    position: relative;
    transition: background 60ms linear;

    &:hover,
    &:active,
    &:focus {
      #{$block}-count-label {
        display: inline-block;
      }
    }

    &_show-controls,
    &:hover:not(#{&}_disabled):not(#{&}_nohover):not(#{&}_active) {
      background: var(--color-bg-hover);
    }

    &_show-controls,
    &:hover:not(#{&}_disabled):not(#{&}_nohover) {
      #{$block} {
        &-badge {
          visibility: visible;
        }

        &-meta-controls {
          opacity: 1;
          position: unset;
        }
      }
    }

    &_opened {
      #{$block}-collapsible-toggle {
        transform: rotate(90deg);
      }

      #{$block}-count-label {
        display: none;
      }
    }

    &_active {
      // Double up specificity b/c we want these to override other state styles.
      &#{&} {
        background: rgba(var(--blue-rgb), 0.09);

        #{$block}-count-label,
        #{$block}-link,
        #{$block}-collapsible-toggle {
          color: var(--blue);
        }

        span.PageNavItem-label {
          color: var(--blue);
        }

        #{$block}-collapsible-toggle-icon {
          opacity: 0.5;
        }

        &:hover {
          background: rgba(var(--blue-rgb), 0.1);
        }
      }
    }

    &_error {
      // Double up specificity b/c we want these to override other state styles.
      &#{&} {
        background: rgba(var(--red-rgb), 0.1);

        #{$block}-count-label,
        #{$block}-link,
        #{$block}-collapsible-toggle {
          color: var(--red40);
        }

        span.PageNavItem-label {
          color: var(--red40);
        }

        #{$block}-collapsible-toggle-icon {
          opacity: 0.5;
        }

        &:hover:not(#{&}_disabled):not(#{&}_nohover):not(#{&}_active),
        &:focus {
          background: rgba(var(--red-rgb), 0.15);
        }
      }
    }

    &_disabled {
      #{$block}-link {
        pointer-events: none;
      }

      &:not(#{$block}-content_active) {
        #{$block}-link {
          opacity: 0.5;
        }
      }
    }

    &_private {
      #{$block}-link {
        opacity: 0.5;
      }
    }
  }

  &-collapsible-toggle {
    background: none;
    border: 0;
    color: var(--gray60);
    cursor: pointer;
    flex: 0 0 auto;
    padding: 2px;

    &[hidden] {
      visibility: hidden;
    }
  }

  &-collapsible-toggle-icon {
    transform: rotate(0deg);
    transition: transform var(--transition-fast) linear;
  }

  &-link {
    align-items: center;
    color: currentcolor;
    cursor: pointer;
    display: flex;
    flex: 1 1 0;
    font-family: var(--font-family);
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    font-weight: var(--font-weight-normal);
    justify-content: flex-start;
    line-height: calc(4 / 3);
    min-width: 0;
    text-decoration: none;
    transition: color var(--transition-fast) var(--transition-timing);

    &:hover {
      color: currentcolor;
    }
  }

  &-label {
    flex: 1 1 auto;
    min-width: inherit;
  }

  &-truncated-label {
    overflow: hidden;
    padding: var(--xs) 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-count-label {
    color: var(--color-text-minimum);
    font-size: 10px;
    font-weight: var(--font-weight-normal);
  }

  &-badge {
    visibility: visible;
  }

  &-meta-controls {
    opacity: 0;
    position: absolute;
  }

  &-children {
    margin: 0;
    padding: var(--PageNav-gap) 0;
  }

  &_dragging {
    color: var(--blue);
    pointer-events: none;

    #{$block} {
      &-status-icon {
        color: var(--blue);
      }
    }
  }

  // fix initial indentation bug
  // https://github.com/readmeio/readme/pull/10285#issuecomment-1783483833
  &_level1 &_level1 &-spacer {
    width: 40px !important;
  }
}
