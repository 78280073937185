@import '@core/styles/vars';

.PageNavDivider {
  border: 0;
  margin-left: var(--PageNav-padding);
  margin-right: var(--PageNav-padding);
  transition: margin var(--transition-fast) linear;

  &_line {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  &_xs {
    margin-bottom: var(--xs);
    margin-top: var(--xs);
  }

  &_sm {
    margin-bottom: var(--sm);
    margin-top: var(--sm);
  }

  &_md {
    margin-bottom: var(--md);
    margin-top: var(--md);
  }

  &_lg {
    margin-bottom: var(--lg);
    margin-top: var(--lg);
  }

  &_dragging {
    margin-bottom: 0;
    margin-top: 0;
  }
}
