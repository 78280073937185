@import '@core/styles/vars';

.PageNavItemStatusIcon {
  color: var(--gray70);
  flex: 0 0 auto;
  font-size: 16px;
  margin-right: var(--xs);
  pointer-events: none;

  &_created {
    color: var(--green);
  }

  &_modified {
    color: var(--yellow);
  }

  &_deleted {
    color: var(--red);
  }

  &_deprecated {
    color: var(--color-text-minimum);
    margin-left: var(--xs);
    margin-right: 0;
  }
}
